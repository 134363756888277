<template>
    <!-- <img :src="SVGswitch" /> -->
    <!-- <SVGswitch/> -->
    <svg width="20" height="17" viewBox="0 0 20 17" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M0.244077 4.75592C-0.081359 4.43049 -0.081359 3.90285 0.244077 3.57742L3.5774 0.244093C3.90284 -0.0813432 4.43047 -0.0813432 4.75591 0.244093L8.08923 3.57742C8.41467 3.90285 8.41467 4.43049 8.08923 4.75592C7.76379 5.08136 7.23616 5.08136 6.91072 4.75592L4.16665 2.01185L1.42258 4.75592C1.09715 5.08136 0.569513 5.08136 0.244077 4.75592Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.99991 12.5002C4.99991 13.1633 5.2633 13.7991 5.73214 14.268C6.20098 14.7368 6.83687 15.0002 7.49991 15.0002L9.16657 15.0002C9.6268 15.0002 9.9999 15.3733 9.9999 15.8335C9.9999 16.2938 9.6268 16.6669 9.16657 16.6669L7.49991 16.6669C6.39484 16.6669 5.33504 16.2279 4.55364 15.4465C3.77224 14.6651 3.33325 13.6053 3.33325 12.5002L3.33325 0.833586C3.33325 0.37335 3.70635 0.000255092 4.16658 0.000255071C4.62682 0.000255051 4.99991 0.37335 4.99991 0.833586L4.99991 12.5002Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9108 11.911C12.2363 11.5855 12.7639 11.5855 13.0893 11.911L15.8334 14.655L18.5775 11.911C18.9029 11.5855 19.4305 11.5855 19.756 11.911C20.0814 12.2364 20.0814 12.764 19.756 13.0895L16.4227 16.4228C16.0972 16.7482 15.5696 16.7482 15.2441 16.4228L11.9108 13.0895C11.5854 12.764 11.5854 12.2364 11.9108 11.911Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0.833586C10 0.37335 10.3731 0.000255092 10.8333 0.000255071L12.5 0.000254999C13.6051 0.00025495 14.6649 0.439241 15.4463 1.22064C16.2277 2.00204 16.6666 3.06184 16.6666 4.16691L16.6666 15.8335C16.6666 16.2938 16.2936 16.6669 15.8333 16.6669C15.3731 16.6669 15 16.2938 15 15.8335L15 4.16691C15 3.50387 14.7366 2.86799 14.2678 2.39915C13.7989 1.93031 13.163 1.66692 12.5 1.66692L10.8333 1.66692C10.3731 1.66692 10 1.29382 10 0.833586Z" />
    </svg>
</template>

<script>
// import { defineComponent } from 'vue'
// import SVGswitch from '@/assets/svg/icon_switchaccount.svg'

// export default defineComponent({
//     name: 'svgComponent',
//     setup() {
//         return {
//             SVGswitch
//         }
//     },
// })
</script>

<style scoped>
img {
    filter: invert(88%) sepia(21%) saturate(3001%) hue-rotate(324deg) brightness(110%) contrast(95%);
}
svg path {
    fill: var(--var-primary) !important;
}
</style>
